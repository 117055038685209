.ant-input-affix-wrapper {
  display: inline-flex;
  position: relative;
}

.ant-input-prefix,
.ant-input-suffix {
  flex: none;
  align-items: center;
  display: flex;
}

@media print {

  /* 
    This CSS rule is applied because Weasyprint applies float properties to align attributes in tables. 
    This causes that the content right after the table is not displayed correctly, covering the table.
    Whit this rule we avoid the previous behavior.
  */
  div,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  table {
    clear: both !important;
  }

  #procedure-scroll {
    position: relative;
    overflow-y: unset;
    width: 100%;
    margin: 0px;
    line-height: normal;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  #execution-info {
    font-family: 'Segoe UI';
    font-style: normal;
    page-break-after: always;
    height: auto;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    orphans: 5;
    margin-left: 1cm;
  }
}

@media screen {
  #procedure-scroll {
    position: relative;
    overflow-y: auto;
    width: 100%;
    margin: 0px;
    line-height: normal;
  }

  #procedure-data {
    display: inline-block !important;
    text-align: initial;
  }
}

#procedure-data .wrapper-revised {
  padding-left: 5px;
  border-left: 2px red solid;
}

#procedure-data .revised:not(tr)::before {
  content: '';
  border-left: 2px red solid !important;
  padding-right: 2px;
}

#procedure-data .revised:is(tr)> :first-child {
  border-left: 2px red solid !important;
}

#execution-info>div.MainTitle {
  font-weight: bold;
  font-size: 24px;
  margin-top: 60px;
  margin-bottom: 50px;
}

#execution-info>div.Title {
  display: flex;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  margin-left: 30px;
  font-weight: bold;
}

#execution-info>div.Info {
  font-size: 16px;
  display: flex;
  margin-left: 30px;
}

#execution-info>pre.Comments {
  margin-left: 30px;
  font-family: inherit;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-y: hidden;
}

#execution-info>div.SectionSeparator {
  margin-bottom: 20px;
}

#execution-info>span.Info {
  margin-right: 5px;
  font-weight: bold;
}

/// Comments

#commentsSection {
  page-break-before: always;
  margin-left: 1cm;
}

.annexedComments {
  font-family: 'Segoe UI';
  font-style: normal;
  text-transform: uppercase;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  page-break-before: always;
  color: #000;
}

.containerComment {
  color: #000;
  break-inside: avoid;

  .comment-content {
    font-size: 14px;
    margin-top: 10px;
    padding-left: 50px;
  }

  .attachment-container {
    display: inline-block;
    border: 1px solid grey;
    padding: 8px 16px 8px 6px;
    margin: 8px 8px 0px 0px;
  }

  hr.comment-divider {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #e1e1db;
    margin: 30px 0;
    padding: 0;
  }
}

.step-comment-title {
  font-size: 16px;
  margin-bottom: 10px;
}

div.titleComments {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
}

.linkToComment,
.linkBack {
  vertical-align: middle;
  color: #4b98c6;
}

a.linkBack div {
  color: #000;
}

.step-comment-tag {
  padding: 1px 8px;
  margin: 0 5px;

  &.nonCompliance {
    color: #fa8c16;
    background: #fff7e6;
    border: 1px solid #ffd591;
  }

  &.important {
    color: #f5222d;
    background: #fff1f0;
    border: 1px solid #ffa39e;
  }

  &.review {
    color: #8cc63f;
    background: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  border-radius: 2px;
}

.video-attachment {
  display: inline-block;
  border: 1px solid gray;
  margin: 10px;
  padding: 8px 16px 8px 6px;
}

.stepParagraphs {
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 8px;

  & .step-circle {
    text-indent: initial;
    position: relative;
    display: inline-block;
    text-align: center;
    min-width: 40px;
    border-radius: 99999px;
  }

  & .is-done .step-line {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 0;
    display: flex;
    position: absolute;
    top: 0;
    left: 0%;
    transform: rotate(45deg);
    border-top: 1px solid #007ec3;
    margin-top: 8px;
  }

  & .is-read .step-circle {
    border: 1px solid #007ec3;
  }

}

#procedure-data .contentcontrol {
  text-indent: initial !important;
}

// Estilos de los optionList
.ant-select {
  background-color: #f5f5f5;
}

.optionlist>.ant-select-selection-item {
  color: #3d3d3d !important;
}

// Recorders

.recorder>.ant-btn {
  border-radius: 2px;
  height: 22px;

  .ant-input {
    border: none;
    height: 22px;
  }
}

// Numerical

.numerical {
  width: 22px;

  span {
    height: 18px;
    border: none;
  }

  .ant-input {
    width: 80px;
    height: 18px;
  }

  .ant-input-suffix svg {
    width: 12px;
    height: 12px;
  }
}

// Formula

.formula {
  width: 22px;

  span {
    height: 18px;
    padding-top: 0px;
    padding-bottom: 0px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.msoIns {
  text-decoration: none !important;
  color: inherit !important;
}

@media print {

  .ant-picker-dropdown,
  .ant-select-dropdown,
  .ant-picker-clear {
    display: none;
  }
}